<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                    <template v-if="organization">
                    &gt; <router-link :to="{ name: 'organization-dashboard', params: { realm: this.$route.params.organizationId } }">{{ organization.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Dynamic DNS records</h1>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col style="text-align: center" cols="12" sm="6">
                    <v-textarea :value="tinydnsconfig"></v-textarea>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mb-8 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Create Service</v-app-bar-title>
                            <!-- <v-spacer/>
                            <v-btn icon @click="createNewItemDialog = true">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn> -->
                        </v-app-bar>

                        <v-card-text>
                            <p>Let's start with the service name and type. You can change these later.</p>
                            <!-- <v-btn style="background-color: green darken-2; color: #ffffff;" elevation="2" @click="createNewItemDialog = true">
                                Create
                            </v-btn> -->
                        </v-card-text>

                        <v-form @submit.prevent="createItem" @keyup.enter.native.prevent="createItem" class="px-2">
                            <v-text-field v-model="newServiceName" label="Service name" hint="The service name. This will be displayed to customers." ref="newServiceNameInput"></v-text-field>
                            <!-- <v-select v-model="newServiceType" :items="serviceTypeChoices" label="Service type"/> -->
                            <v-list v-if="serviceTypeList.length > 0">
                                <v-list-item-group v-model="newServiceTypeIndex" color="green darken-2">
                                <v-list-item v-for="(item, idx) in serviceTypeList" :key="idx">
                                    <v-list-item-icon>
                                        <font-awesome-icon :icon="item.icon" fixed-width></font-awesome-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.label }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                </v-list-item-group>
                            </v-list>

                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="createItem" :disabled="!isNewItemFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <!-- <v-btn text color="grey" @click="createNewItemDialog = false">
                                <span>Cancel</span>
                            </v-btn> -->
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        organization: null,
        serviceTypeList: [
            { label: 'Membership', value: 'membership', icon: ['fas', 'id-badge'] },
            { label: 'Subscription', value: 'subscription', icon: ['fas', 'file-invoice-dollar'] },
            { label: 'Software as a Service', value: 'saas', icon: ['fas', 'code'] },
            // { label: 'Software as a Service - Ticket', value: 'saas-ticket', icon: ['fas', 'file-invoice-dollar'] },
        ],

        submitFormTimestamp: null,

        newServiceName: null,
        newServiceTypeIndex: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            serviceTypeChoices: (state) => state.serviceTypeChoices,
        }),
        isNewItemFormComplete() {
            return typeof this.newServiceName === 'string' && this.newServiceName.length > 0 && typeof this.newServiceType === 'string' && this.newServiceType.length > 0;
        },
        newServiceType() {
            if (!Number.isInteger(this.newServiceTypeIndex) || !Array.isArray(this.serviceTypeList)) {
                return null;
            }
            if (this.newServiceTypeIndex < 0 || this.newServiceTypeIndex >= this.serviceTypeList.length) {
                return null;
            }
            return this.serviceTypeList[this.newServiceTypeIndex].value;
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async createItem() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            try {
                this.$store.commit('loading', { createItem: true });
                const item = {
                    name: this.newServiceName,
                    type: this.newServiceType,
                };
                const response = await this.$client.organization(this.$route.params.organizationId).accountService.create(item);
                if (response?.isCreated && response.id) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.$router.push({ name: 'organization-edit-service', params: { organizationId: this.$route.params.organizationId }, query: { id: response.id, published: 'false' } });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('createItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { createItem: false });
            }
        },
    },
    mounted() {
        this.loadOrganization();
    },
};
</script>
